<template>
  <div class="flex gap-4">
    <Avatar size="10" :user="comment?.user" />
    <div class="flex-auto bg-[#F4F6F8] p-3 rounded-lg grid gap-1">
      <div class="flex items-center justify-between">

        {{  }}
        <h6 class="text-sm font-semibold text-primary">{{   full_name ?? ''}}</h6>
        <span class="text-xs text-disabled">{{ formattedDate(comment?.updated_at) }}</span>
      </div>
      <p class="text-xs text-disabled">{{ comment?.content }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Avatar from '@/Components/Avatar.vue';
import moment from 'moment';

const props = defineProps({
  comment: Object,
  user: Object,
});

const formattedDate = computed(() => {
  return function (date) {
    return moment(date).format('DD MMM YYYY');
  };
});
const full_name = computed(() => {
 return props?.comment?.user.fullname
});
</script>
